.block-benefits {
    --font-size: var(--font-size-xlarge);
    --font-size-number: var(--font-size-xxxlarge);
    --font-size-thumbnail: var(--font-size-xxxsmall);
    --width-slide: 70vw;
    --height-slide: 50vh;

    --gap: var(--padding-m);

    --padding-v: 25vh;
    --padding-h: var(--padding-m);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xl);
        --padding-h: var(--padding-s);
        --width-slide: 80vw;
        --height-slide: 50vh;
    }
}

.block-benefits {
    position: relative;
    width: 100vw;
    overflow: hidden;

    [data-scroll-sticky] { @include z-index(0); }

    .holder {
        display: flex;
        height: 100%;
        width: auto;
        gap: var(--gap);
    }
    
    .slide {
        position: relative;
        width: var(--width-slide);
        height: 100%;

        align-items: center;
        display: flex;
        justify-content: center;

        &:first-child,
        &:last-child {
            //width: 50vw;
        }

        &:not(.--active) {
            .card {
                backdrop-filter: blur(var(--blur));
                // * {
                //     filter: blur(var(--blur-text));
                // }
            }
        }
        
        &.--active {
            .card {
                background-color: var(--secondary-color);
            }
        }
    }

    .card {
        @include font-sans(1.07);
        font-size: var(--font-size);

        width: var(--width-slide);
        height: var(--height-slide);
        border-radius: var(--radius);
        padding: var(--padding-s);
        // background-color: var(--primary-bg);
        background-color: var(--panel);
        transition: background .2s ease-out;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        * {
            transition: filter .2s ease-out;
        }

        .number {
            font-size: var(--font-size-number);
        }
    }

    .thumbnails {
        display: flex;
        margin-top: var(--padding-s);
        gap: var(--padding-s);
        padding: 0 var(--padding-h);

        position: absolute;
        bottom: calc(var(--padding-v) - var(--padding-l));

        li {
            border-top: 1px solid var(--secondary-color);
            color: var(--secondary-color);
            width: rem(38);
            transition: width .3s ease-in-out;

            @include font-mono();
            font-size: var(--font-size-thumbnail);
            text-align: left;
            padding-top: var(--padding-xxs);

            &.--active {
                width: rem(198);
            }
        }
    }

    @media (max-width: $smartphone) {
        .fake {
            display: none;
        }

        .thumbnails {
            display: none;
        }

        .card {
            background-color: var(--secondary-color);
        }

        > div {
            height: 100%;
            width: auto;
            overflow: hidden;
        }
        
        .holder {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .slide {
            &:first-child,
            &:last-child { width: var(--padding-h); }

            scroll-snap-align: start;
        }
    }

    @media (min-width: $smartphone) {
        .block-benefits__holder {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
        }
    
        .fake {
            position: relative;
            width: 100%;
            height: 71.4285vh;
            pointer-events: none;
        }
        
        [data-scroll-sticky] {
            height: 100vh;
            position: absolute;
            width: 100vw;
            overflow: hidden;
        }
        
        .holder {
            left: 0;
            position: absolute;
            top: 0;
        }
    }
}
