.block-cta {
    --font-size-title: var(--font-size-xxxlarge);
    --padding-h: var(--padding-m);
    --padding-v: var(--padding-m);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-s);
        --padding-v: var(--padding-s);
    }
}

.block-cta {
    width: 100vw;
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    > div {
        padding: var(--padding-v) var(--padding-h);
        max-width: calc(rem(882) + var(--padding-h) * 2);
        background-color: var(--panel);
        border-radius: var(--radius);
        backdrop-filter: blur(var(--blur));
    }

    h2 {
        @include font-sans-medium(1.07);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-m);
        
        strong, b {
            @include font-sans-bold(1.07);
            color: var(--secondary-color);
        }
    }

    @media (max-width: $smartphone) {
        padding: var(--padding-xxxxl) var(--padding-h);
    }

    @media (min-width: $smartphone) {
        min-height: calc(100vh - var(--footer-height));
    }
}
