.pop-up {
    --padding: var(--padding-m);

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    @media (max-width: $smartphone) {
        --padding: var(--padding-xs);
    }
}

.pop-up {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    cursor: auto;

    @include z-index($z-index-interface);

    display: flex;
    justify-content: center;
    align-items: center;

    padding: var(--padding);

    .overlay {
      @include basic-a();

      background-color: transparent;

      @include z-index(0);
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    }

    .wrapper {
        background-color: var(--panel);
        border-radius: var(--radius);

        padding: var(--padding);

        width: calc(100vw - 2 * var(--padding));
        max-width: rem(1200);
        max-height: calc(100vh - 2 * var(--padding));
    }
}
