@use "sass:math";

#CMP {
    --color: var(--primary-color);
    --bg: var(--panel);

    --font-size: var(--font-size-xsmall);
    --padding: var(--padding-m);
    
    --gap-general: var(--padding-xs);
    
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    
    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
        --padding: var(--padding-s);

        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

#CMP {
    @include z-index($z-index-windows);
    @include font-sans();

    position: fixed;
    left: var(--padding-h);
    bottom: var(--padding-v);
    // width: 100%;
    max-height: 100vh;
    max-width: 50vw;
    opacity: 0;
    
    background-color: var(--bg);
    border-radius: var(--radius);
    backdrop-filter: blur(var(--blur));

    color: var(--color);
    font-size: var(--font-size);
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
    .content {
        flex: 1 0 40%;
        display: block;
        position: relative;

        padding: var(--padding) calc(var(--padding) + var(--gap-general)) var(--padding) var(--padding);
    }

    > .btns {
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap-btns);
        padding: var(--padding) var(--padding) var(--padding) 0;
    }

    a {
        @include basic-a();
        color: var(--color);
        text-decoration: underline;
    }
    
    @media (max-width: $smartphone) {
        max-width: calc(100vw - 2 * var(--padding));

        .content {
            padding: var(--padding);
            flex: 1 0 100%;
        }
    
        > .btns {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 0 var(--padding) var(--padding);
            flex: 1 0 100%;
    
            > .btn:first-child {
                grid-column: 1 / 3;
            }
        }
    }
}