#Scrollbar {
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    --font-size: var(--font-size-xxxsmall);
    --font-size-number: var(--font-size-xxxxsmall);

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-s);
        
        display: none !important;
    }
}

#Scrollbar {
    @include z-index($z-index-scrollbar);
    @include font-mono();
    font-size: var(--font-size);

    position: fixed;
    bottom: var(--padding-v);
    right: var(--padding-h);
    
    align-items: center;
    display: flex;
    gap: var(--padding-xs);
    
    .breadcrumbs {
        color: var(--secondary-color);
        padding: 0 var(--padding-s);
        width: auto;

        a {
            @include basic-a();
            color: var(--secondary-color);
        }

        span {
            margin: 0 var(--padding-xxxs);
        }
    }

    svg {
        height: rem(36);
        width: rem(36);
        stroke: var(--secondary-color);
        fill: transparent;
        
        .progress {
            stroke-dasharray: var(--progress), 100;
        }
    }

    .panel {
        position: relative;

        .number {
            font-size: var(--font-size-number);
    
            position: absolute;
            left: 0;
            top: 0;
            
            // height: 100%;
            // width: 100%;
            height: var(--height);
            width: var(--width);
    
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    @media (max-width: $smartphone) {
        .breadcrumbs {
            display: none;
        }
    }
}
