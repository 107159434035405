#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--primary-color);
    // --bg: var(--panel);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    // --font-size: var(--font-size-base);
    
    --logo-width: #{rem(36)};
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-s);
    }
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    top: 0;
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    color: var(--color);

    a {
        @include basic-a();
    }

    .logo {
        height: var(--logo-width);
        width: var(--logo-width);

        svg {
            height: 100%;
            width: 100%;

            fill: var(--primary-color);
        }
    }
}
