.block-avatars {
    --font-size-title: var(--font-size-xlarge);
    --font-size: var(--font-size-small);
    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-m);
    --width-slide: 100vw;
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xl);
        --padding-h: var(--padding-s);
    }
}

.block-avatars {
    position: relative;
    width: 100vw;
    overflow: hidden;

    // [data-scroll-sticky] { @include z-index(0); }

    .holder {
        display: flex;
        height: 100%;
        width: auto;
    }
    
    .slide {
        @include font-sans();
        color: var(--primary-color);
        font-size: var(--font-size);

        position: relative;
        width: var(--width-slide);

        align-items: flex-start;
        display: flex;
        flex-direction: column;

        > div {
            display: flex;

            > div:first-child {
                max-width: rem(525);
            }
            
            > div:last-child {
                max-width: rem(350);

                figure {
                    margin-top: -10vh;
                    background-color: var(--panel);
                    border-radius: var(--radius);
                    backdrop-filter: blur(var(--blur));
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    h2 {
        @include font-sans-medium(1.07);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-m);
    }

    .text {
        @include font-sans();
        font-size: var(--font-size);
                
        strong, b {
            @include font-sans-bold();
        }
    }

    .info {
        display: none;
    }
    
    @media (max-width: $smartphone) {
        .slide {
            padding: 20vh 20vw 20vh var(--padding-h);
        }
    }

    @media (min-width: $smartphone) {
        height: 400vh;

        // [data-scroll-sticky] {
        //     height: 100vh;
        //     position: relative;
        //     width: 100vw;
        // }

        .slide {
            height: 100vh;
            width: 100vw;
            // overflow: hidden;
            // position: absolute;
            // top: 0;
            // left: 0;
            padding: var(--padding-v) var(--padding-h) 0;
        }

        .slide:nth-child(n + 2) {
            padding-top: calc(var(--padding-v) + var(--padding-m));
        } 

        .slide:last-child {
            height: 200vh;
        }

        h2,
        .text {
            transition: opacity .2s ease-out;
            transition-delay: 0s;
        }

        .holder {
            left: 0;
            position: absolute;
            top: 0;
        }
    }
}
