#Footer {
    --font-size: var(--font-size-xxxsmall);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-s);
    }
}

#Footer {
    @include font-mono();
    font-size: var(--font-size);
    position: relative;

    .wrapper {
        padding: var(--padding-v) var(--padding-h);
    }

    .panel {
        svg {
            height: var(--social-icon);
            width: var(--social-icon);
            fill: var(--primary-bg);
        }
    }

    .legals {
        display: flex;
        align-items: center;
        gap: var(--padding-xs);
        flex-wrap: wrap;
    }

    .newsletter {
        @include isCursor() {
            .link:hover {
                --animation: 0s;
            }
        }
    }

    .rrss {
        display: flex;
        justify-content: center;
        gap: var(--padding-s);

        .panel {
            height: var(--height);
        }
    }
    
    .legals {
        display: flex;
    }

    .newsletter {
        flex: 0 1 100%;
        margin-bottom: var(--padding-xxs);
    }
    
    @media (max-width: $smartphone) {
        .wrapper {
            display: flex;
            flex-direction: column;

            .panel,
            .link {
                --bg: var(--panel);

                svg {
                    fill: var(--primary-color);
                }
            }

            .link {
                background-color: var(--panel);
                backdrop-filter: blur(var(--blur));
                color: var(--primary-color);
                padding: var(--padding-xxxs);
                border-radius: var(--padding-xxxs);
            }

            .divider {
                display: none;
            }

            .rrss {
                order: 1;
                margin-bottom: var(--padding-m);
            }

            .legals {
                order: 2;
            }
        }
    }

    @media (min-width: $smartphone) {
        height: var(--footer-height);

        .wrapper {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;

            > div {
                flex: 0 1 30%;
            }

        }
    }
}
