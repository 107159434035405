@mixin button {
    --color: var(--primary-color);
    --bg: var(--secondary-color);
    --border: var(--secondary-color);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-m);
    --font-size: var(--font-size-xsmall);
    --min-width: #{rem(145)};
    
    &.--sm {
        --min-width: #{rem(120)};
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-s);
        --font-size: var(--font-size-xsmall);
    }

    &.--hollow {
        --bg: transparent;
        --color: var(--secondary-color);
    }

    @include font-mono(1);

    align-items: center;
    appearance: none;
    background: var(--bg);
    border: 1px solid var(--border);
    border-radius: var(--radius);
    cursor: pointer;
    color: var(--color);
    display: inline-flex;
    font-size: var(--font-size);
    justify-content: center;
    padding: var(--padding-v) var(--padding-h);
    position: relative;
    text-align: center;
    vertical-align: top;
    text-transform: uppercase;
    backdrop-filter: blur(var(--blur));

    text-align: center;
    min-width: var(--min-width);

    * { pointer-events: none; }

    span {
        display: block;
        text-decoration: underline;
    }

    svg {
        height: var(--padding-s);
        width: var(--padding-s);
        margin-left: var(--padding-xs);
        fill: var(--color);
        
        path {
            fill: var(--color);
        }
    }
}

.button {
    @include button;
}