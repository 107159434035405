@mixin link() {
    @include font-mono();
    @include basic-a();

    position: relative;
    text-decoration: underline;
    color: var(--primary-color);

    padding: 0;
    background-color: transparent;

    /// TOUCH
    // @include isTouch() {
    //     overflow: visible;

    //     &:before {
    //         opacity: 0;
    //         transform: translateX(0);
    //     }

    //     > span {
    //         transform: translateX(0);
    //     }

    //     &:hover {
    //         &:before {
    //         opacity: 1;
    //         }
    //     }
    // }

    &.--marquee {
        width: rem(180);
        display: flex;
        overflow: hidden;
        border-radius: var(--radius);
        text-decoration: none;

        span {
            @include basic-marquee();
        }
    }
}

.link {
    @include link();
}
