html {
    width: 100%;
    height: 100%;
}

body {
    width: 100vw;
    min-height: 100%;
    color: var(--primary-color);
    background: var(--primary-bg);
    // background: linear-gradient(175deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);

    > video {
        visibility: hidden;
        pointer-events: none;
    }
    
    main {
        width: 100%;

        // opacity: 0 !important;
    }
    
    &:not(.__scroll-manual) {
        overflow: hidden;
        height: 100vh;

        .wrap {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
        }
    }
    
    &.__scroll-manual:not(.__noScroll) {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        
        &.__noScroll {
            overflow: hidden;
        }
    }
}

.icon {
    display: inline-block;

    svg {
        height: 100%;
        width: 100%;
    }
}

.line-parent {
    overflow: hidden;
}

*[aria-current="page"] {
    pointer-events: none;
    text-decoration: none;
}

button[disabled] {
    pointer-events: none;
    opacity: .3;
}

// Tweakpane
.tp-dfwv {
    @include z-index($z-index-tweakpane);

    @media (max-width: $smartphone) {
        display: none !important;
    }
}

.shffl {
    position: relative;

    .main {
        opacity: 0;
    }

    .effect {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
    }
}

[data-shffl] {
    > div {
        min-height: 1em;
    }
}