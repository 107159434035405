.block-title-text {
    --font-size-title: var(--font-size-xxxlarge);
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-xxxlarge);
        --font-size: var(--font-size-base);
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-s);
    }
}

.block-title-text {
    width: 100vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--padding-v) var(--padding-h);
    
    > div {
        padding: var(--padding-v) var(--padding-h);
        max-width: calc(rem(805) + var(--padding-h) * 2);
        background-color: var(--panel);
        border-radius: var(--radius);
        backdrop-filter: blur(var(--blur));
    }

    h2 {
        @include font-sans-medium(1.07);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-m);
    }

    .text {
        @include font-sans();
        font-size: var(--font-size);
                
        strong, b {
            @include font-sans-bold();
        }
    }

    @media (max-width: $smartphone) {
        padding: var(--padding-xxxl) var(--padding-h);
    }

    @media (min-width: $smartphone) {
        min-height: 100vh;
    }
}
