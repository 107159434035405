#Interface__Canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include z-index($z-index-interface);
  pointer-events: none;
}

body > canvas {
  position: fixed;
  @include z-index(0);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

#scene-bg {
    position: fixed;
    @include z-index(0);
    top: 0;
    left: 0;
    width: 100vw;
    pointer-events: none;
}

#scene-target {
    pointer-events: none;
    position: fixed;
    @include z-index(0);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    .target {
        height: 35vw;
        width: 35vw;
    }
    
    @media (min-width: $smartphone) {
        .target {
            height: 9vw;
            width: 9vw;
        }
    }
}