@use "sass:math";

:root {
    --y-header: 0;

    --padding-xxxxxl: #{math.div(216px, 16px) * 1rem};
    --padding-xxxxl: #{math.div(146px, 16px) * 1rem};
    --padding-xxxl: #{math.div(108px, 16px) * 1rem};

    --padding-xl: #{math.div(72px, 16px) * 1rem};
    --padding-l: #{math.div(54px, 16px) * 1rem};
    --padding-m: #{math.div(36px, 16px) * 1rem};
    --padding-s: #{math.div(18px, 16px) * 1rem};
    --padding-xs: #{math.div(9px, 16px) * 1rem};
    --padding-xxs: #{math.div(4px, 16px) * 1rem};
    --padding-xxxs: #{math.div(2px, 16px) * 1rem};

    --header-height: #{math.div(126px, 16px) * 1rem};
    --footer-height: #{math.div(126px, 16px) * 1rem};

    --social-icon: #{math.div(46px, 16px) * 1rem};

    --radius: #{math.div(23px, 16px) * 1rem};
    --blur: 5px;
    --blur-text: 2px;

    @media (max-width: $smartphone) {
        // --padding-xxxxxl: #{math.div(216px, 16px) * 1rem};
        // --padding-xxxxl: #{math.div(146px, 16px) * 1rem};
        // --padding-xxxl: #{math.div(108px, 16px) * 1rem};

        --padding-xl: #{math.div(44px, 16px) * 1rem};
        --padding-l: #{math.div(32px, 16px) * 1rem};
        --padding-m: #{math.div(20px, 16px) * 1rem};
        --padding-s: #{math.div(14px, 16px) * 1rem};
        --padding-xs: #{math.div(6px, 16px) * 1rem};
        --padding-xxs: #{math.div(4px, 16px) * 1rem};

        --header-height: #{math.div(86px, 16px) * 1rem};
        --footer-height: #{math.div(112px, 16px) * 1rem};

        // --social-icon: #{math.div(46px, 16px) * 1rem};

        --radius: #{math.div(14px, 16px) * 1rem};
    }
}
