.block-results {
    --font-size-title: var(--font-size-xlarge);
    --font-size-number: var(--font-size-xxxxlarge);
    --font-size-text: var(--font-size-xsmall);
    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-m);
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-xlarge);
        --padding-v: var(--padding-xxxxl);
        --padding-h: var(--padding-s);
    }
}

.block-results {
    width: 100vw;
    padding: 0 var(--padding-h) var(--padding-v);

    > div:first-child {
        max-width: rem(630);
        padding-top: var(--padding-v);
    }

    h2 {
        @include font-sans-medium(1.07);
        font-size: var(--font-size-title);
    }

    .text {
        @include font-sans();
        font-size: var(--font-size-xsmall);
        line-height: 1;
        margin-top: var(--padding-xs);
    }

    &__items {
        margin-top: var(--padding-xl);
    
        .panel {
            padding: var(--padding-s) var(--padding-xs);
    
            &.--number,
            &.--percentage {
                @include font-sans(1);
                font-size: var(--font-size-number);
            }
    
            &.--text {
                @include font-mono();
                font-size: var(--font-size-text);
            }
        }
    }

    @media (max-width: $smartphone) {
        &__item {
            &:not(:last-child) {
                margin-bottom: var(--padding-s);
            }

            .card-result {
                .panel:first-child {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
                
                .panel:last-child {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        // margin-top: 50vh;

        &__items {
            margin-top: var(--padding-xxxxxl);

            .card-result {
                display: flex;
                justify-content: flex-end;
            }
        
            .panel {
                padding: var(--padding-m) var(--padding-s);
        
                &.--number {
                    span {
                        margin: 0 var(--padding-s);
                    }
                }
                
                &.--text {
                    max-width: rem(250);
                }
            }
        }
    }
}
