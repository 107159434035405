:root {
    --white: #ffffff;
    --black: rgba(6,8,4,1);
    --grey: #999;

    --primary: var(--black);
    --secondary: var(--white);

    --panel: rgba(16, 19, 30, .4);
    --panel-hover: rgba(16, 19, 30, .8);

    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #4dc247;
}