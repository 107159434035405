.panel {
    --color: var(--secondary-color);
    --bg: transparent;
    --padding: var(--padding-l);
    
    &.--md {
        --height: var(--padding-l); 
        --width: var(--padding-l);
    }

    &.--xl {
        --height: var(--padding-xl); 
        --width: var(--padding-xl);

        --icon-height: #{rem(14)};
        --icon-width: #{rem(9)};
    }

    &.--xxl {
        --height: var(--padding-xxxl); 
        --width: var(--padding-xxxl);
    }

    &:not(.--transparent) {
        --bg: var(--panel);
    }
}

.panel {
    @include basic-a();

    min-height: var(--height);
    width: var(--width);

    background-color: var(--bg);
    border-radius: var(--radius);
    backdrop-filter: blur(var(--blur));

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        fill: var(--secondary-color);
    }
}

button.panel,
a.panel {
    @include isCursor() {
        transition: background-color .2s ease-out;

        &:hover {
            background-color: var(--panel-hover);
        }
    }
}
