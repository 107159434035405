.block-landing {
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    
    --font-size-title: var(--font-size-xxxlarge);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-s);
    }
}

.block-landing {
    width: 100vw;
    min-height: 110vh;
    padding: 0 var(--padding-h) var(--padding-v);

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;

    h1 {
        @include font-sans-medium(1.07);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-m);
        
        strong, b {
            @include font-sans-bold(1.07);
            color: var(--secondary-color);
        }
    }
}
