.gui {
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);

    --gap: var(--padding-xs);

    @media (max-width: $smartphone) {
        display: none !important;
    }
}

.gui {
    @include z-index($z-index-interface);

    position: fixed;
    bottom: var(--padding-h);
    left: var(--padding-v);

    display: flex;
    gap: var(--gap);
    align-items: flex-end;

    .panel {
        svg {
            width: var(--icon-width);
            height: var(--icon-height);
        }

        &.--info {
            @include font-mono();
            font-size: var(--font-size-xxsmall);

            display: flex;
            align-items: center;

            padding: var(--padding-s) var(--padding-m);
            
            width: rem(396);
            
            > div {
                flex-grow: 1;
            }
        }
    }
}
