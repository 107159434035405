@keyframes ellipsis {
  to {
    width: rem(12);
  }
}

#Preloader {
    --font-size: var(--font-size-xsmall);
    --color: var(--secondary-color);
    --bg: var(--primary-bg);
    --padding: var(--padding-m);

    // @media (max-width: $smartphone) {
    //     --font-size: 4vw;
    // }
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    color: var(--color);
    background-color: var(--bg);

    padding: var(--padding);
    
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;

    @include font-mono();
    font-size: var(--font-size);

    .progress,
    .loading {
        opacity: 0;
    }

    .loading {
        margin-bottom: var(--padding-xxs);

        .effect:after {
            overflow: hidden;
            display: inline-block;
            vertical-align: bottom;
            animation: ellipsis steps(4, end) 900ms infinite;
            content: "\2026";
            width: 0px;
            margin-left: rem(2);
        }
    }

    .number {
      width: rem(30);
      display: inline-block;
      text-align: right;
    }
    
    @media (max-width: $smartphone) {
        .number {
          width: rem(25);
        }
    }
}
